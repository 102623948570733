<template>
    <b-modal v-model="exibe"
             body-class="app-modal-custom-body"
             header-class="app-modal-custom-header"
             footer-class="app-modal-custom-footer"
             size="lg"
             no-enforce-focus>
        <template v-slot:default="{ cancel, ok }">

            <div>
                <div class="container">
                    <span id="titulo_modal_categoria">{{editar ? 'Editar' : 'Criar'}} Categoria</span>
                    <div class="row pt-2">
                        <div class="col">
                            <label for="">Codigo:</label>
                            <input type="text" class="modal_categoria_input" v-model="codigo">
                        </div>
                        <div class="col">
                            <label for="">Nome:</label>
                            <input type="text" class="modal_categoria_input" v-model="nome">
                        </div>
                    </div>
                    <div class="row py-2">
                        <div class="col-12">
                            <botao-modal-ok class="float-right ml-2" @click="salvarCategoria()">
                                Confirmar
                            </botao-modal-ok>
                            <botao-modal-cancel type="button" class="float-right ml-2" @click="cancel()"> Cancelar </botao-modal-cancel>
                        </div>
                    </div>

                </div>
            </div>

        </template>

        <template v-slot:modal-footer>
            <div class="mr-5">
               
            </div>
        </template>

    </b-modal>
</template>

<script>
    import BotaoModalOk from '@/components/botoes/BotaoModalOk.vue';
    import BotaoModalCancel from '@/components/botoes/BotaoModalCancel.vue';
    import ProdutosServices from '@/assets/js/services/ProdutosServices'
    export default {
        name: 'ModalMensagensEmail',

        components: {
            BotaoModalOk,
            BotaoModalCancel,
            ProdutosServices
        },

        props: {
            itensEdicao: '',
            tipo: String,
            editarCategoria: Boolean,
            
        },

        data: function () {
            return {
                exibe: false,
                erros: [],
                id: null,
                codigo: '',
                nome: '',
                mensagemHtml: '',
                data: '',
                editar: false,
                

            }
        },
        watch: {
            editarCategoria() {
                this.editar = this.editarCategoria
            },
            itensEdicao() {
                this.data = this.itensEdicao;
                if (!this.data) {
                    return
                }
                this.id = this.itensEdicao.Id
                this.codigo = this.itensEdicao.Codigo
                this.nome = this.itensEdicao.Nome  
            }
        },
        methods: {
            show() {
                this.limpa()
                this.exibe = true
                if (!this.data) {
                    return
                }
                this.id = this.data.Id
                this.codigo = this.data.Id
                this.nome = this.data.Nome
            },

            salvarCategoria: async function () {
                $("#caixa_carregamento").show()
                const dados = {
                    Nome: this.nome,
                    Codigo: this.codigo,
                    Acao: this.editar ? 'EDITAR':'SALVAR'
                }
                if (this.editar) dados.Id = this.id;
                var retornoCarregamento = await ProdutosServices.salvarCategoria(dados);
                this.$emit("retornoCarregamento", retornoCarregamento)
                $("#caixa_carregamento").hide()
                this.exibe = false;
            },
            limpa() {
                this.data = ''
                this.erros = []
                this.codigo = ''
                this.nome = ''
                this.assunto = ''
            },
           
        }
    }
</script>

<style>
    .modal-mensagens-email-lista-erros {
        color: red;
    }

    .modal_categoria_input {
        background-color: var(--cinza-2);
        color: var(--cinza-5);
        width: 100%;
        border: 1px solid var(--cinza-4);
        outline: none;
    }

    #titulo_modal_categoria {
        font-size: 15px;
        font-family: verdana;
        font-weight: bold;
    }
</style>